import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

interface ErrorProps {
  title: string
  radioError?: boolean
  subtitle?: string
}
const ErrorMessage: FunctionComponent<ErrorProps> = ({
  title,
  radioError,
  subtitle,
}) => {
  return (
    <Container>
      <ErrorTitle radioError={radioError}>{title}</ErrorTitle>
      {subtitle && <p>{subtitle}</p>}
    </Container>
  )
}

const Container = styled.div`
  & p {
    color: #d0021b;
    font-weight: 300;

    & a,
    & a:link,
    a:visited {
      font-size: inherit;
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }
  }
`
interface ErrorTitleProps {
  radioError?: boolean
}
const ErrorTitle = styled.section<ErrorTitleProps>`
  color: #d0021b;
  font-size: 15px;
  font-weight: 300;
  line-height: 26px;
  margin-top: 10px;

  animation-name: shake;
  animation-duration: 1s;
  animation-iteration-count: 1;

  ${(props) =>
    props.radioError &&
    `
    margin-top: -25px;
    margin-bottom: 35px;
    `};

  @media only screen and (max-width: 850px) {
    line-height: 24px;
    font-size: 14px;
  }
`

export default ErrorMessage
