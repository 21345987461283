import { AnyAaaaRecord } from 'dns'
import { Link } from 'gatsby'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { appendUrlPartnerTracking } from '../../../util/url'
import theme from '../../styling/theme'

interface BtnProps {
  onClick? : (e: React.MouseEvent) => void 
  width?: string
  maxWidth?: string
  small?: boolean
  secondary?: boolean
  tertiary?: boolean
  inactive?: boolean
  href?: string
  quaternary?: boolean
  className?: string
}

const BtnPrimaryRound: FunctionComponent<BtnProps> = ({
  children,
  onClick,
  width,
  maxWidth,
  small,
  secondary,
  tertiary,
  inactive,
  href,
  quaternary,
  className
}) => {
  return (
    <>
    {!(href?.startsWith('/')) ?

    <Container
      onClick={!inactive ? onClick : (() => null)}
      width={width}
      maxWidth={maxWidth}
      small={small}
      secondary={secondary}
      tertiary={tertiary}
      quaternary={quaternary}
      inactive={inactive}
      href={appendUrlPartnerTracking(href)}
      className={className}
    >
      {children}
    </Container>

    :
    
    <Container
      onClick={!inactive ? onClick : (() => null)}
      width={width}
      maxWidth={maxWidth}
      small={small}
      secondary={secondary}
      tertiary={tertiary}
      quaternary={quaternary}
      inactive={inactive}
      to={appendUrlPartnerTracking(href)}
      className={className}
    >
      {children}
    </Container>}
    
    </>
    
  )
}

export default BtnPrimaryRound

interface ContainerProps {
  width?: string
  maxWidth?: string
  small?: boolean
  secondary?: boolean
  tertiary?: boolean
  inactive?: boolean
}

const Container = styled(Link)<ContainerProps>`
  background: ${theme.brand.red};
  width: ${(props) => (props.width ? props.width : '100%')};
  min-width: 100px;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '400px')};
  height: ${(props) => (props.small ? '40px' : '60px')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  font-size: ${(props) => (props.small ? '12px' : '14px')};
  font-weight: bold;
  text-align: center;
  color: white !important;
  cursor: pointer;
  border: 1px solid ${theme.brand.red};
  transition: background 0.3s ease;

  & span {
    font-weight: bold;
  }

  ${(props) =>
    props.secondary &&
    `
      color: ${theme.brand.red} !important;
      background: white;
      font-weight: 400;
    `}
  ${(props) =>
    props.tertiary &&
    `
          color: ${theme.text.white} !important;
          border: 1px solid ${theme.text.white};
          background: transparent;
          font-weight: 400;
        `}
  ${(props) =>
    props.small &&
    `
          color: ${theme.brand.red} !important;
          background: ${theme.brand.red};
          border: 1px solid ${theme.brand.red};
          color: white !important;
          font-weight: 500;
          height: 26px !important;
          font-size: 12px !important;
          min-width: 80px;
        `}
    ${(props) =>
    props.inactive &&
    `
      background: #d9d9d9;
      border: 1px solid #d9d9d9;
      cursor: initial;
      `}
@media (max-width: 768px) {
    height: 50px;
  }
  @media only screen and (max-width: 365px) {
    font-size: 17px !important;
  }

  &:hover {
    background-color: ${theme.brand.redHover};
    border-color: ${theme.brand.redHover};
    color: ${theme.text.white} !important;
    text-decoration: none !important;
    ${(props) =>
      props.inactive &&
      `
      background: #d9d9d9;
      border: 1px solid #d9d9d9;
      cursor: initial;
      `}

    ${(props) =>
      props.secondary &&
      `
        color: white !important;
        background: ${theme.brand.red};
      `}

      ${(props) =>
      props.tertiary &&
      `
            color: ${theme.text.white} !important;
            border: 1px solid ${theme.brand.red};
            background: ${theme.brand.red};
          `}
  }

  @media only screen and (max-width: 850px) {
    font-size: 18px;
  }
  & b,
  & strong {
    font-weight: bold;
  }
`
