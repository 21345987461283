import React, { FC } from 'react'
import styled from 'styled-components'
//import TrustpilotReviews from '@pittica/gatsby-plugin-trustpilot-widget'

/*class Trustpilot extends Component {
    render() {
      return (
        <TrustpilotReviews
          language="en"
          culture="GB"
          theme="light"
          width="100%"
          height="150px"
          template="53aa8807dec7e10d38f59f32"
          business="5e2183d61d20eb000130ca6e"
          username="bobsbusiness.co.uk"
        />
      )
    }
}*/

interface TrustBoxInterface {
  darkMode?: boolean
}

const TrustBox: FC<TrustBoxInterface> = ({ darkMode }) => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null)
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    const script = document.createElement('script')
    script.src =
      'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
    script.async = true
    document.head.appendChild(script)
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])
  return (
    <div
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      className="trustpilot-widget" // Renamed this to className.
      // [ long list of data attributes...]
      style={{ display:"flex", alignItems:"center"}}
      data-locale="en-GB"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="5e2183d61d20eb000130ca6e"
      data-style-height="40px"
      data-style-width="100px"
      data-theme={darkMode ? 'dark' : 'light'}
    >
      <a
        href="https://www.trustpilot.com/review/bobsbusiness.co.uk"
        target="_blank"
        rel="noopener"
      >
        {' '}
        Trustpilot
      </a>
    </div>
  )
}

export default TrustBox
