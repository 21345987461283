import { createGlobalStyle } from 'styled-components'
// import theme from "../../style/theme"
const GlobalStyles = createGlobalStyle`



* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;


}


  html{
    overflow-x: hidden;
    
  }
  body {



  }
  h1 {
   

    @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
     
  }
  }
  h2{
  
    @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
 
  }
    
  }
  h3 {

    @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
     
  }
  }
  h4 {
   
  }  
  h5 {

  }
  h6 {

  }
  a {
 
    &:hover {
     

    }
  }
  b {

  }
  p{

    @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {

  }

  }

`

export default GlobalStyles
