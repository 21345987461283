export const validateEmail = (email: string) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validateURL = (url: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(url)
}

export const scrollTo = async (id: string) => {
  const element = document.getElementById(id)
  if (element && element.scrollIntoView) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}
export const validateOnBlur = (
  isValidated: boolean,
  toggleError: any
): void => {
  if (isValidated) {
    toggleError(false)
  } else {
    toggleError(true)
  }
}

export const inRange = (x: number, min: number, max: number): boolean => {
  return min <= x && x <= max
}

export const validatePhoneNumber = (phone: string | number): boolean => {
  const uk = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
  const international = /^\+(?:[0-9] ?){6,14}[0-9]$/
  return uk.test(phone.toString()) || international.test(phone.toString())
}

export const scrollPage = (id: string) => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
}

export const getItem = (property: string): string | null => {
  if (typeof window !== 'undefined') {
    return window.sessionStorage.getItem(property) || ''
  }
  return ''
}

export const setItem = (property: string, value: string): void => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(property, value)
  }
}
