export const appendUrlPartnerTracking = (url: string) => {
  if (typeof window !== 'undefined') {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const partner = urlParams.get('partner')
    let newUrl = url
    if (partner !== null) {
      newUrl += `?partner=${partner}`
    }
    return newUrl
  } else {
    return url;
  }
}
