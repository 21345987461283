import React from 'react'
import { ThemeProvider } from 'styled-components'
// import "@fontsource/barlow-condensed/500.css"
// import "@fontsource/barlow-condensed/600.css"

const theme = {
  breakpoints: {
    xl: '1920px',
    lg: '1280px',
    md: '960px',
    sm: '600px',
  },
}
const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme
